import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SubPageTitle from '../components/sub-page-title/subPageTitle';
import BriefForm from "../components/brief-form/brief-form";
import Button from "../components/button/button";
import SectionDescription from "../components/section-description/section-description";
import Slider from "../components/slider/slider";
import Header from "./../components/header/header";
import {graphql} from "gatsby";

export const query = graphql`
  query {
    slider: file(relativePath: { eq: "jtw-3d.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 499, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    allYaml {
      nodes {
        brief {
          seo{
            title,
            description,
            lang
          }
          title{
            line1,
            line2,
            line3
          },
          description,
          submitButton,
          thanksUrl,
          form{
            contactData,
            description,
            website,
            website,
            shop,
            application,
            elements,
            sending,
            required,
            label{
              fullName,
              company,
              phone,
              email,
              price,
              project,
              projectDescription,
              endDate,
              examples,
              goal,
              design,
              agree1,
              agree2,
              description,
              elements
            }
          }
        }
      }
    }
  }
`

export default class Brief extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.title = React.createRef();
    this.formWrapper = React.createRef();
    this.submitForm = this.submitForm.bind(this);

    this.state = {
      loading: false,
      translations: props.data.allYaml.nodes[0].brief,
      sliderImage: props.data.slider,
    };
  }

  submitForm(event){
    event.preventDefault();
    this.setState({
      loading: true
    });
    let success = true;
    this.form.current.submitForm();
    
    for( const field in this.form.current.state.validator){
      if(this.form.current.state.validator[field].error === true){
        this.setState({
          loading: false
        });
        success = false;
      }
    }

    if(success){
      navigate('/dziekujemy-za-wypelnienie-briefu');
    }
  }

  buttonActive(){
    return <Button 
      cssClass="section-brief--submit section-brief--submit--mobile" 
      text={this.state.translations.submitButton}
      url="#"
      clickCallback={this.submitForm} 
    />
  }

  buttonDisabled(){
    return <Button 
      cssClass="section-brief--submit section-brief--submit--mobile section-brief--submit--disabled" 
      text={this.state.translations.form.sending} 
      url="#"
    />
  }

  render(){
    return <Layout>
    <SEO 
        title={this.state.translations.seo.title}
        description={`${this.state.translations.seo.description}.`}
        url="https://jointoweb.pl/brief"
        lang={this.state.translations.seo.lang}
        htmlClass="footer--hide-logo"
    />
    <Header>
      <Slider 
        image={this.state.sliderImage.childImageSharp.fluid} 
        textLeft="Wypełnij"
        textRight="Brief" />
    </Header>
    <div className="section section--brief">
      <div className="section-brief--title" ref={this.title}>
        <SubPageTitle text={<span>Brief<strong> UX/UI & Design&nbsp;System</strong></span>}/>
        <div className="section-text--description">
          <SectionDescription content={`${this.state.translations.description}.`}/>
        </div>
      </div>
      <div className="section-brief--form" ref={this.formWrapper}>
        <BriefForm ref={this.form} translations={this.state.translations.form}/>
        { !this.state.loading ? this.buttonActive() : this.buttonDisabled() }
        <div className="section-brief--help">Pola oznaczone gwiazdką (*) są wymagane</div>
      </div>
    </div>
  </Layout>
  }
}
