import React from "react";
import TextField from "@material-ui/core/TextField";
import Slider from '@material-ui/core/Slider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import { navigate } from "gatsby";
import FormLabel from '@material-ui/core/FormLabel';

import "./brief-form.scss";

export default class BriefForm extends React.Component{
    marks = [
        {
            label: 0,
            value: 0
        },
        {
            label: 2000,
            value: 2000
        },
        {
            label: 5000,
            value: 5000
        },
        {
            label: 10000,
            value: 10000
        },
        {
            label: 15000,
            value: 15000
        },
        {
            label: 20000,
            value: 20000
        }
    ];
    
    constructor(props){
        super(props);

        this.state = {
            loading: false,
            projectDefault: 'website',
            translations: props.translations,
            form: {
                fullName: '',
                company: '',
                phone: '',
                email: '',
                price: '',
                project: '',
                projectDescription: '',
                endDate: null,
                examples: '',
                goal: '',
                design: '',
                mailing: false,
                contactAgreement: false,
                contactAgreement2: false,
            },
            validator: {
                fullName: {
                    error: false,
                    message: null
                },
                company: {
                    error: false,
                    message: null
                },
                phone: {
                    error: false,
                    message: null
                },
                email: {
                    error: false,
                    message: null
                },
                contactAgreement: {
                    error: false,
                    message: null
                },
            },
            
        };
        this.handleChange = this.handleChange.bind(this);
        this.addCheckbox = this.addCheckbox.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }
    componentDidMount(){
        if(typeof window !== 'undefined'){
            const query = window.location.search;

            if(query.length >0){
                const queryParams = query.replace('?','').split('&');

                const briefParams = queryParams[0].split('=');
                if(briefParams[0] === 'project'){
                    const form = this.state.form;
                    form.project = briefParams[1];
                    this.setState(form);
                }
            }
        }
    }
    submitForm(){
        const data = this.state.form;
        let status = true;
        
        for(const field in data){
            if( this.state.validator[field] === undefined){
                continue;
            }

            let validator = this.state.validator;

            if(this.state.form[field] === '' 
                || this.state.form[field] === null 
                || this.state.form[field] === false){
                validator[field].error = true;
                validator[field].message = this.state.translations.required;
                status = false;
            }else{
                validator[field].error = false;
                validator[field].message = null;
            }

            this.setState({
                validator: validator
            });
        }

        if(status){
            this.setState({
                loading: true
            });

            data['form-name'] = 'brief';
            let body = new URLSearchParams(data).toString();

            fetch('/', {
                method: 'POST',
                headers: { "Content-Type": "application/x-www-form-urlencoded" }, 
                body: body
            }).then( (response) => {
                if(response.status){
                    navigate(this.state.translations.thanksUrl);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }
    }
    handleChange(event,value) {
        const fieldValue = event.target.value || value;
        const name = event.target.name || 'price';
        const form = this.state.form;
        form[name] = fieldValue;

        this.setState(form);
        
        if( this.state.validator[name] !== undefined){
            let validator = this.state.validator;
            validator[name].error = false;
            validator[name].message = null;

            this.setState({
                validator: validator
            });
        }
    }
    setEndDate(date){
        let form = this.state.form;
        form.endDate = date;
        this.setState(form);
    }
    addCheckbox(e,v){
        const form = this.state.form;
        form[e.target.name] = e.target.checked;
        this.setState(form);
    }
    render(){
        return <form name="brief" noValidate data-netlify="true" autoComplete="off" onSubmit={this.submitForm}>
            <input type="hidden" name="form-name" value="Brief" />
            <fieldset className="fieldset">
                <legend className="legend">{this.state.translations.contactData}</legend>
                <TextField 
                    label={this.state.translations.label.fullName}
                    name="fullName"
                    fullWidth={true} 
                    required={true}
                    value={this.state.form.fullName}
                    onChange={this.handleChange}
                    error={this.state.validator.fullName.error}
                    helperText={this.state.validator.fullName.message}
                    disabled={this.state.loading}
                />
                
                <TextField 
                    label={this.state.translations.label.company}
                    name="company" 
                    fullWidth={true} 
                    required={true}
                    value={this.state.form.company}
                    onChange={this.handleChange} 
                    error={this.state.validator.company.error}
                    helperText={this.state.validator.company.message}
                    disabled={this.state.loading}
                />
                <TextField 
                    label={this.state.translations.label.phone}
                    name="phone" 
                    fullWidth={true} 
                    required={true}
                    onChange={this.handleChange}  
                    value={this.state.form.phone}
                    error={this.state.validator.phone.error}
                    helperText={this.state.validator.phone.message}
                    disabled={this.state.loading}
                />
                <TextField 
                    label={this.state.translations.label.email}
                    name="email" 
                    fullWidth={true} 
                    required={true} 
                    type="email"
                    onChange={this.handleChange}
                    value={this.state.form.email}
                    error={this.state.validator.email.error}
                    helperText={this.state.validator.email.message}
                    disabled={this.state.loading}
                />
                <Typography id="price-slider" gutterBottom>
                    {this.state.translations.label.price}
                </Typography>
                <Slider 
                    aria-labelledby="price-slider"
                    max={20000}
                    step={1000}
                    defaultValue={0}
                    valueLabelDisplay="off"
                    marks={this.marks}
                    name="price"
                    onChange={this.handleChange}
                    disabled={this.state.loading}
                />
            </fieldset>
            <fieldset className="fieldset">
                <legend className="legend">{this.state.translations.label.description}</legend>
                <InputLabel id="projectType">{this.state.translations.label.project}</InputLabel>
                <Select
                    className="select"
                    labelId="projectType"
                    fullWidth={true} 
                    required={true} 
                    onChange={this.handleChange}
                    name="project"
                    value={this.state.form.project}
                    defaultValue={this.state.projectDefault}
                    disabled={this.state.loading}
                >
                    <MenuItem value="website">{this.state.translations.website}</MenuItem>
                    <MenuItem value="shop">{this.state.translations.shop}</MenuItem>
                    <MenuItem value="application">{this.state.translations.application}</MenuItem>
                </Select>
                <TextField 
                    label={this.state.translations.label.projectDescription}
                    name="projectDescription" 
                    multiline
                    fullWidth={true} 
                    onChange={this.handleChange}
                    value={this.state.form.projectDescription}
                    disabled={this.state.loading}
                />
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker 
                        label={this.state.translations.label.endDate}
                        format="Y-MM-dd"
                        value={this.state.form.endDate}
                        fullWidth={true}
                        disableToolbar
                        onChange={this.setEndDate} 
                        disablePast
                        disabled={this.state.loading}
                    />
                </MuiPickersUtilsProvider> */}
            </fieldset>
            <fieldset className="fieldset" style={{marginTop: '25px'}}>
                <legend className="legend">{this.state.translations.label.elements}</legend>
                <TextField 
                    label={this.state.translations.label.examples}
                    name="examples" 
                    fullWidth={true} 
                    onChange={this.handleChange}
                    disabled={this.state.loading}
                />
                <TextField 
                    label={this.state.translations.label.goal}
                    name="goal" 
                    fullWidth={true} 
                    onChange={this.handleChange}
                    disabled={this.state.loading}
                />
                <TextField 
                    label={this.state.translations.label.design}
                    name="design"
                    fullWidth={true} 
                    onChange={this.handleChange}
                    disabled={this.state.loading}
                />
                <FormControl
                    className="MuiFormControl-root--checkbox" 
                    required={true}
                    disabled={this.state.loading}
                    error={this.state.validator.contactAgreement.error}
                    onChange={this.addCheckbox}>
                    <Checkbox name="contactAgreement" /> 
                    <FormLabel>
                        { this.state.translations.label.agree1}
                    </FormLabel>
                </FormControl>

                <FormControl
                    className="MuiFormControl-root--checkbox" 
                    required={true}
                    disabled={this.state.loading}
                    onChange={this.addCheckbox}>
                    <Checkbox name="mailing" /> 
                    <FormLabel>
                        {this.state.translations.label.agree2}
                    </FormLabel>
                </FormControl>
            </fieldset>
        </form>
    }
}
